import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, AbstractControl } from '@angular/forms';
import { UserTokenCreate } from '../../models/user-token-create.interface';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-registration',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  registrationForm!: FormGroup;
  fieldTextType: boolean;

  constructor(private formBuilder: FormBuilder, private auth: AuthService, public userService: UserService, private router: Router) {}

  ngOnInit(): void {
    this.registrationForm = this.formBuilder.group(
      {
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.minLength(8)]],
        confirmPassword: ['', [Validators.minLength(8)]],
      },
      { validators: this.passwordMatchValidator },
    );
  }

  passwordMatchValidator(control: AbstractControl) {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');
    return password && confirmPassword && password.value === confirmPassword.value ? null : { noMatchFound: true };
  }

  disableButton() {
    if (
      (this.fieldTextType && this.registrationForm.controls.password.status === 'VALID') ||
      (this.registrationForm.controls.password.status === 'VALID' && this.registrationForm.controls.confirmPassword.status === 'VALID')
    ) {
      return false;
    }
    return true;
  }
  accountRegistration() {
    const user: UserTokenCreate = {
      email: this.registrationForm.value.email,
      password: this.registrationForm.value.password,
    };

    this.userService.createUser(user).subscribe(() => {
      this.auth.login(user).subscribe(() => {
        this.router.navigate(['/registration']);
      });
    });
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
}
