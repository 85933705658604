import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { ResetPassword } from 'src/app/models/reset-password.interface';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
})
export class SetPasswordComponent implements OnInit {
  setPasswordForm!: FormGroup;
  fieldTextType: boolean;
  token: string;
  uid: string;
  showMessage = false;

  constructor(private formBuilder: FormBuilder, public userService: UserService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.setPasswordForm = this.formBuilder.group({
      uid: [''],
      token: [''],
      new_password: ['', [Validators.required, Validators.minLength(8)]],
    });
    this.uid = this.route.snapshot.queryParamMap.get('uid');
    this.token = this.route.snapshot.queryParamMap.get('token');
  }

  setPassword() {
    if (this.setPasswordForm.valid) {
      const reset: ResetPassword = {
        uid: this.uid,
        token: this.token,
        new_password: this.setPasswordForm.value.new_password,
      };
      this.userService.setNewPassword(reset).subscribe(() => (this.showMessage = true));
    }
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
}
