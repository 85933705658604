<header class="b-header">
  <div class="b-header__container container pure-g">
    <div class="b-header__column pure-u-2-5">
      <a routerLink="/login" class="b-header__img-wrapper">
        <img src="../../../assets/images/russian-coffee-cup-logo.svg" alt="Russian Coffee Cup Logo" />
      </a>
    </div>
    <div class="b-header__column b-header__column--login pure-u-3-5">
      <div class="b-header__button-container" *ngIf="!auth.isAuthenticated()">
        <a
          href="../../../assets/files/Регламент онлайн этапа Russian Coffee Cup 2022.pdf"
          target="_blank"
          class="b-header__button b-header__button--blue pure-button"
          >Регламент</a
        >
        <button
          (click)="toggleLoginPopup(); $event.stopPropagation()"
          [class.login-active]="showLoginPopup"
          class="b-header__button b-header__button--red pure-button"
        >
          Вход
        </button>
      </div>
      <div *ngIf="showLoginPopup" class="b-header__login-form" (clickOutside)="onClickedOutside($event)">
        <form [formGroup]="loginForm" (ngSubmit)="login()" class="b-header__form pure-form pure-form-stacked">
          <fieldset>
            <p class="b-header__login-form-title">Вход</p>
            <div class="b-header__form-item">
              <label class="b-header__input-label input-label">E-mail</label>
              <input class="b-header__input-field" type="email" formControlName="email" required />
              <div *ngIf="loginForm.controls['email'].invalid && loginForm.controls['email'].touched" class="validation">
                <small *ngIf="loginForm.controls['email'].errors?.required">Поле Email не может быть пустым</small>
                <small *ngIf="loginForm.controls['email'].errors?.email">Введите корректный email</small>
              </div>
            </div>
            <div class="b-header__form-item">
              <label class="b-header__input-label input-label">Пароль</label>
              <input
                [type]="fieldTextType ? 'text' : 'password'"
                class="b-header__input-field"
                formControlName="password"
                required
                minlength="6"
              />
              <span
                [ngClass]="{ 'show-password': !fieldTextType, 'hide-password': fieldTextType }"
                (click)="toggleFieldTextType()"
                class="password-toggle"
              ></span>
            </div>
            <div *ngIf="loginForm.controls['password'].touched" class="validation">
              <small *ngIf="loginForm.controls['password'].errors?.required">Поле пароль не может быть пустым</small>
            </div>
            <div class="validation" *ngIf="auth.errors$ | async as error">
              <small>{{ error }}</small>
            </div>
            <button type="submit" [disabled]="loginForm.invalid" class="b-header__submit-button pure-button">
              Войти
            </button>
            <a routerLink="/reset-password" class="b-header__restore-button">Восстановить пароль</a>
          </fieldset>
        </form>
      </div>
      <div class="b-header__user" *ngIf="auth.isAuthenticated(); else socialIcons">
        <div class="b-header__user-container">
          <div *ngIf="team$ | async as team" class="b-header__user-block">
            <div class="b-header__user-img-container" *ngIf="team.logo_image">
              <img class="b-header__user-img" src="{{ team.logo_image }}" alt="{{ team.name }}" />
            </div>
            <div class="b-header__user-controls">
              <div class="b-header__user-name">{{ team.name }}</div>
              <button (click)="logout()" class="b-header__logout-button pure-button">Выход</button>
            </div>
          </div>
          <div *ngIf="judgeService.isJudge$ | async" class="b-header__user-block">
            <a routerLink="/judge" [queryParams]="{ show: 'all' }" class="b-header__button b-header__button--red pure-button">
              Команды
            </a>
            <div class="b-header__user-img-container">
              <img class="b-header__user-img" src="{{ user.avatar }}" alt="{{ user.firstname }}{{ user.secondname }}" />
            </div>
            <div class="b-header__user-controls">
              <div class="b-header__user-name">
                {{ user.firstname }}<br />
                {{ user.secondname }}
              </div>
              <button (click)="logout()" class="b-header__logout-button pure-button">Выход</button>
            </div>
          </div>
        </div>
      </div>
      <ng-template #socialIcons>
        <div class="b-header__social-icons">
          <a href="https://vk.com/russiancoffeecup" rel="noopener noreferrer" target="_blank" class="b-header__icon"
            ><i class="icon-vkontakte"></i
          ></a>
          <a href="https://zen.yandex.ru/pircoffee" rel="noopener noreferrer" target="_blank" class="b-header__icon"
            ><i class="icon-zen"></i
          ></a>
          <a href="https://t.me/pircoffee" rel="noopener noreferrer" target="_blank" class="b-header__icon"
            ><i class="icon-telegram"></i
          ></a>
        </div>
      </ng-template>
    </div>
  </div>
</header>
