import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UserTokenCreate } from '../models/user-token-create.interface';
import { environment } from '../../environments/environment';
import { ResetPassword } from '../models/reset-password.interface';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  apiUrl = environment.apiUrl;
  public errors$: Subject<string> = new Subject<string>();
  constructor(private http: HttpClient, private router: Router) {}

  createUser(user: UserTokenCreate): Observable<any> {
    return this.http.post(`${this.apiUrl}/v1/auth/users/`, user).pipe(
      catchError((error) => {
        Object.values(error.error).map((x: any) => {
          x.map((e: string) => {
            if (e === 'user with this email address already exists.') {
              x[x.indexOf(e)] = 'Пользователь с таким Email уже существует';
              this.errors$.next(x);
            }
            if (e === 'This password is too short. It must contain at least 8 characters.') {
              x[x.indexOf(e)] = 'Длина пароля не менее 8 символов';
              this.errors$.next(x);
            }
            if (e === 'This password is too common.') {
              x[x.indexOf(e)] = 'Распространенный пароль';
              this.errors$.next(x);
            }
            if (e === 'This password is entirely numeric.') {
              x[x.indexOf(e)] = 'Пароль полностью числовой';
              this.errors$.next(x);
            }
          });
        });
        return throwError(error.error);
      }),
    );
  }

  resetPassword(email) {
    return this.http.post(`${this.apiUrl}/v1/auth/users/reset_password/`, email).pipe(
      catchError((error) => {
        // console.error(error);
        return throwError(error);
      }),
    );
  }

  setNewPassword(reset: ResetPassword) {
    return this.http.post(`${this.apiUrl}/v1/auth/users/reset_password_confirm/`, reset).pipe(
      catchError((error) => {
        // console.error(error);

        Object.values(error.error).map((x: any) => {
          x.map((e: string) => {
            if (e === 'This password is too short. It must contain at least 8 characters.') {
              x[x.indexOf(e)] = 'Длина пароля не менее 8 символов';
              this.errors$.next(x);
            }
            if (e === 'This password is too common.') {
              x[x.indexOf(e)] = 'Распространенный пароль';
              this.errors$.next(x);
            }
            if (e === 'This password is entirely numeric.') {
              x[x.indexOf(e)] = 'Пароль полностью числовой';
              this.errors$.next(x);
            }
            if (e === 'Invalid token for given user.') {
              x[x.indexOf(e)] = 'Неверный токен для данного пользователя';
              this.errors$.next(x);
            }
          });
        });
        return throwError(error);
      }),
    );
  }
}
