import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { User } from '../models/user.interface';

@Injectable({
  providedIn: 'root',
})
export class LoggedInGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (this.auth.isAuthenticated()) {
      this.auth.getUser().subscribe((user: User) => {
        user.groups.map((x: any) => {
          x.name === 'Judge' ? (this.auth.redirectUrl = '/judge') : (this.auth.redirectUrl = '/registration');
        });
        this.router.navigate([`${this.auth.redirectUrl}`], { queryParams: { show: 'all' } });
      });
      return false;
    } else {
      return true;
    }
  }
}
