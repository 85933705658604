<footer class="b-footer">
  <div class="b-header__container container pure-g">
    <div class="b-footer__column pure-u-1-1">
      <div class="b-footer__social-icons">
        <a href="https://vk.com/pircoffee" target="_blank" rel="noopener noreferrer" class="b-footer__icon"
          ><i class="icon-vkontakte"></i
        ></a>
        <a href="https://t.me/pircoffee" target="_blank" rel="noopener noreferrer" class="b-footer__icon"
          ><i class="icon-telegram"></i
        ></a>
        <a href="https://zen.yandex.ru/pircoffee" target="_blank" rel="noopener noreferrer" class="b-footer__icon"
          ><i class="icon-zen"></i
        ></a>
        <a
          href="https://www.youtube.com/channel/UC1mANrYJwoBJzGWagb_bO2Q?view_as=subscriber"
          target="_blank"
          rel="noopener noreferrer"
          class="b-footer__icon b-footer__icon--large"
          ><i class="icon-youtube"></i
        ></a>
      </div>
      <p class="b-footer__copyright-text">ПИР, 1997-2020. Все права защищены.</p>
    </div>
  </div>
</footer>
