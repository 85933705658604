import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { UserTokenCreate } from '../../models/user-token-create.interface';
import { Router, NavigationEnd } from '@angular/router';
import { TeamService } from '../../services/team.service';
import { Team } from '../../models/team.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../../models/user.interface';
import { JudgeService } from '../../services/judge.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  loginForm!: FormGroup;
  public team$: Observable<Team>;
  public showLoginPopup = false;
  public fieldTextType: boolean;
  public user: User;

  constructor(
    private formBuilder: FormBuilder,
    private teamService: TeamService,
    private router: Router,
    public auth: AuthService,
    public judgeService: JudgeService,
  ) {}

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.showLoginPopup = false;
      }
    });
    if (this.auth.isAuthenticated()) {
      this.getUser();
    }

    // console.log('%c Authenticated ', 'color: white; background-color: #2274A5', this.auth.isAuthenticated());
  }

  toggleLoginPopup() {
    this.showLoginPopup = !this.showLoginPopup;
  }
  onClickedOutside(e: Event) {
    this.toggleLoginPopup();
  }

  getTeam() {
    this.team$ = this.teamService.getTeam().pipe(
      map((team) => {
        if (team.status === 'DRAFT') {
          this.auth.redirectUrl = '/registration';
          this.router.navigate(['/registration']);
        } else {
          this.auth.redirectUrl = '/coffeeshop';
          this.router.navigate(['/coffeeshop']);
        }
        return team;
      }),
    );
  }

  getUser() {
    this.auth.getUser().subscribe((user: User) => {
      if (user.groups.length) {
        user.groups.map((x: any) => {
          if (x.name === 'Judge') {
            this.router.navigate(['/judge'], { queryParams: { show: 'all' } });
            this.judgeService.isJudge$.next(true);
            this.user = user;
          }
        });
      } else {
        this.getTeam();
      }
    });
  }

  login() {
    const user: UserTokenCreate = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
    };
    this.auth.login(user).subscribe(() => {
      this.toggleLoginPopup();
      this.getUser();
    });
  }

  logout() {
    this.auth.logout().subscribe(() => {
      this.router.navigate(['/']);
      localStorage.removeItem('coffee-token');
      this.judgeService.isJudge$.next(false);
    });
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
}
