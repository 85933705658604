<section class="b-reset-password">
  <div class="pure-g container">
    <h2 class="b-reset-password__sub-title title-heading sub-title pure-u-1-1">Восстановление пароля</h2>
    <div class="pure-u-1-1">
      <div class="pure-g">
        <div class="b-reset-password__form-container pure-u-1-2">
          <form [formGroup]="resetForm" (ngSubmit)="resetPassword()" class="b-reset-password__form pure-form pure-form-stacked">
            <fieldset>
              <div class="b-reset-password__form-item">
                <label class="b-reset-password__input-label input-label" for="email">E-mail</label>
                <input class="b-reset-password__input-field" type="email" id="email" formControlName="email" required />
                <div *ngIf="resetForm.controls['email'].invalid && resetForm.controls['email'].touched" class="validation">
                  <small *ngIf="resetForm.controls['email'].errors?.required">Поле Email не может быть пустым</small>
                  <small *ngIf="resetForm.controls['email'].errors?.email">Введите корректный Email</small>
                </div>
              </div>

              <button type="submit" [disabled]="resetForm.invalid" class="b-reset-password__submit-button pure-button">
                Сбросить пароль
              </button>
            </fieldset>
          </form>
        </div>
        <div *ngIf="showMessage" class="pure-u-1-1 success-message">
          На почту отправлено письмо с инструкцией по восстановлению пароля
        </div>
      </div>
    </div>
  </div>
</section>
