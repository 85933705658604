import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, throwError, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Team } from '../models/team.interface';
import { Drink } from '../models/drink.interface';
import { Member } from '../models/member.interface';

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  apiUrl = environment.apiUrl;
  public errors$: Subject<string> = new Subject<string>();
  public success$: Subject<string> = new Subject<string>();
  messageShow = false;

  constructor(private http: HttpClient) {}

  messageTimeout() {
    this.messageShow = true;
    setTimeout(() => {
      this.messageShow = false;
    }, 4000);
  }

  getTeam(): Observable<Team> {
    return this.http.get<Team>(`${this.apiUrl}/v1/team/profile`).pipe(
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  getTeamById(id: number): Observable<Team> {
    return this.http.get<Team>(`${this.apiUrl}/v1/team/${id}`).pipe(
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  getAllTeams() {
    return this.http.get<Team>(`${this.apiUrl}/v1/team/all`).pipe(
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  getMembers(): Observable<Member[]> {
    return this.http.get<Member[]>(`${this.apiUrl}/v1/team/member/list`).pipe(
      catchError((error) => {
        // console.error(error.message);
        return throwError(error);
      }),
    );
  }

  getDrinks(): Observable<Drink[]> {
    return this.http.get<Drink[]>(`${this.apiUrl}/v1/team/drink/list`).pipe(
      catchError((error) => {
        // console.error(error.message);
        return throwError(error);
      }),
    );
  }

  sendTeam(team: any, status: string): Observable<any> {
    if (status === 'DRAFT') {
      return this.http.put(`${this.apiUrl}/v1/team/profile`, team, { observe: 'response' }).pipe(
        map((r) => {
          if (r.status === 200) {
            this.success$.next(r.statusText);
            this.messageTimeout();
          }
        }),

        catchError((error) => {
          this.errors$.next(error);
          this.messageTimeout();
          return throwError(error);
        }),
      );
    } else {
      return this.http.patch(`${this.apiUrl}/v1/team/profile`, team).pipe(
        catchError((error) => {
          this.errors$.next(error);
          this.messageTimeout();
          return throwError(error);
        }),
      );
    }
  }

  createMember(member: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/v1/team/member/list`, member).pipe(
      catchError((error) => {
        this.errors$.next(error);
        this.messageTimeout();
        return throwError(error);
      }),
    );
  }

  updateMember(member: any, id: number): Observable<any> {
    return this.http.put(`${this.apiUrl}/v1/team/member/${id}`, member).pipe(
      catchError((error) => {
        this.errors$.next(error);
        this.messageTimeout();
        return throwError(error);
      }),
    );
  }

  createDrink(drink: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/v1/team/drink/list`, drink).pipe(
      catchError((error) => {
        this.errors$.next(error);
        this.messageTimeout();
        return throwError(error);
      }),
    );
  }

  updateDrink(drink: any, id: number): Observable<any> {
    return this.http.put(`${this.apiUrl}/v1/team/drink/${id}`, drink).pipe(
      catchError((error) => {
        this.errors$.next(error);
        this.messageTimeout();
        return throwError(error);
      }),
    );
  }

  deleteDrink(n: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/v1/team/drink/${n}`);
  }

  deleteMember(n: number): Observable<any> {
    return this.http.delete(`${this.apiUrl}/v1/team/member/${n}`);
  }
}
