<section class="b-set-password">
  <div class="pure-g container">
    <h2 class="b-set-password__sub-title title-heading sub-title pure-u-1-1">Введите новый пароль</h2>
    <div class="pure-u-1-1">
      <div class="pure-g">
        <div class="b-set-password__form-container pure-u-1-2">
          <form [formGroup]="setPasswordForm" (ngSubmit)="setPassword()" class="b-set-password__form pure-form pure-form-stacked">
            <fieldset>
              <div class="b-set-password__form-item b-set-password__form-item--password">
                <label class="b-set-password__input-label input-label" for="password">Пароль</label>
                <input
                  [type]="fieldTextType ? 'text' : 'password'"
                  class="b-set-password__input-field"
                  id="password"
                  formControlName="new_password"
                />
                <span
                  [ngClass]="{ 'show-password': !fieldTextType, 'hide-password': fieldTextType }"
                  (click)="toggleFieldTextType()"
                  class="password-toggle"
                ></span>
              </div>
              <div
                *ngIf="setPasswordForm.controls['new_password'].invalid && setPasswordForm.controls['new_password'].touched"
                class="validation"
              >
                <small *ngIf="setPasswordForm.controls['new_password'].errors?.required">Поле пароль не может быть пустым</small>
                <small *ngIf="setPasswordForm.controls['new_password'].errors?.minlength"
                  >Длина пароля должна быть не менее
                  {{ setPasswordForm.controls['new_password'].errors?.minlength.requiredLength }} символов</small
                >
              </div>
              <div class="validation" *ngIf="userService.errors$ | async as errors">
                <small *ngFor="let error of errors">{{ error }}</small>
              </div>

              <button type="submit" [disabled]="setPasswordForm.invalid" class="b-set-password__submit-button pure-button">
                Отправить
              </button>
            </fieldset>
          </form>
        </div>
        <div *ngIf="showMessage" class="pure-u-1-1 success-message">
          Новый пароль успешно установлен
        </div>
      </div>
    </div>
  </div>
</section>
