import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { UserTokenCreate } from '../models/user-token-create.interface';
import { Observable, throwError, Subject } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { AuthResponse } from '../models/auth-response.interface';
import { environment } from '../../environments/environment';
import { User } from '../models/user.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  apiUrl = environment.apiUrl;
  public errors$: Subject<string> = new Subject<string>();
  public name$: Subject<string> = new Subject<string>();
  public redirectUrl = '/registration';
  constructor(private http: HttpClient) {}

  get token(): string | null {
    const token = localStorage.getItem('coffee-token');
    if (!token) {
      return null;
    } else {
      return localStorage.getItem('coffee-token');
    }
  }
  login(user: UserTokenCreate): Observable<any> {
    return this.http.post(`${this.apiUrl}/v1/auth-token/token/login/`, user).pipe(
      tap((val: any) => this.setToken(val)),
      catchError((error) => {
        // console.error(error.message);
        this.errors$.next('Неверный Email или пароль');
        return throwError(error);
      }),
    );
  }

  logout(): Observable<any> {
    return this.http.post(`${this.apiUrl}/v1/auth-token/token/logout/`, this.token, {
      headers: new HttpHeaders({
        Authorization: `Token ${this.token}`,
      }),
    });
  }

  isAuthenticated(): boolean {
    return !!this.token;
  }

  private setToken(response: AuthResponse | null) {
    // console.log('%c Token ', 'color: white; background-color: green', response?.auth_token);

    if (response) {
      localStorage.setItem('coffee-token', response.auth_token);
    } else {
      localStorage.clear();
    }
  }

  getUser(): Observable<User> {
    return this.http.get<User>(`${this.apiUrl}/v1/auth/users/me/`).pipe(
      // tap((user) => {
      //   this.name$.next('user');
      // }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }),
    );
  }
}
