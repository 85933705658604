<section class="b-login">
  <div class="pure-g container">
    <h1 class="b-login__main-title title-heading main-title pure-u-1-1">Регистрация</h1>
    <h2 class="b-login__sub-title title-heading sub-title pure-u-1-1">Регистрационные данные</h2>
    <div class="b-login__form-container pure-u-1-1">
      <div class="pure-g">
        <div class="pure-u-1-2">
          <form [formGroup]="registrationForm" (ngSubmit)="accountRegistration()" class="b-login__form pure-form pure-form-stacked">
            <fieldset>
              <div class="b-login__form-item">
                <label class="b-login__input-label input-label" for="email">E-mail для регистрации</label>
                <input class="b-login__input-field" type="email" id="email" formControlName="email" required />
                <div *ngIf="registrationForm.controls['email'].invalid && registrationForm.controls['email'].touched" class="validation">
                  <small *ngIf="registrationForm.controls['email'].errors?.required">Поле Email не может быть пустым</small>
                  <small *ngIf="registrationForm.controls['email'].errors?.email">Введите корректный Email</small>
                </div>
              </div>
              <div class="b-login__form-item b-login__form-item--password">
                <label class="b-login__input-label input-label" for="password">Пароль</label>
                <input
                  [type]="fieldTextType ? 'text' : 'password'"
                  class="b-login__input-field"
                  id="password"
                  formControlName="password"
                  required
                  minlength="8"
                />
                <span
                  [ngClass]="{ 'show-password': !fieldTextType, 'hide-password': fieldTextType }"
                  (click)="toggleFieldTextType()"
                  class="password-toggle"
                ></span>
              </div>
              <div
                *ngIf="registrationForm.controls['password'].invalid && registrationForm.controls['password'].touched"
                class="validation"
              >
                <small *ngIf="registrationForm.controls['password'].errors?.required">Поле пароль не может быть пустым</small>
                <small *ngIf="registrationForm.controls['password'].errors?.minlength"
                  >Длина пароля должна быть не менее
                  {{ registrationForm.controls['password'].errors?.minlength.requiredLength }} символов</small
                >
              </div>
              <div class="b-login__form-item b-login__form-item--password">
                <div *ngIf="!fieldTextType">
                  <label class="b-login__input-label input-label" for="confirmPassword">Повторите пароль</label>
                  <input
                    type="password"
                    class="b-login__input-field"
                    id="confirmPassword"
                    formControlName="confirmPassword"
                    required
                    minlength="8"
                  />
                  <!-- <span
                    [ngClass]="{ 'show-password': !repeatFieldTextType, 'hide-password': repeatFieldTextType }"
                    (click)="toggleRepeatFieldTextType()"
                    class="password-toggle"
                  ></span> -->
                </div>
              </div>
              <div *ngIf="registrationForm.controls['confirmPassword'].touched && !fieldTextType" class="validation">
                <small *ngIf="registrationForm.controls['confirmPassword'].errors?.required">Поле пароль не может быть пустым</small>
                <small *ngIf="registrationForm.controls['confirmPassword'].errors?.minlength"
                  >Длина пароля должна быть не менее
                  {{ registrationForm.controls['confirmPassword'].errors?.minlength.requiredLength }} символов</small
                >
                <small *ngIf="registrationForm.errors?.noMatchFound">Пароли не совпадают</small>
              </div>
              <div [class.validation-offset]="fieldTextType" class="validation" *ngIf="userService.errors$ | async as errors">
                <small *ngFor="let error of errors">{{ error }}</small>
              </div>
              <button type="submit" [disabled]="disableButton()" class="b-login__submit-button pure-button">
                Зарегистрироваться
              </button>
            </fieldset>
          </form>
          <a routerLink="/reset-password" class="b-login__restore-button pure-button button-xsmall">Восстановить пароль</a>
        </div>
        <div class="b-login__text pure-u-1-2">
          <p>Спасибо за регистрацию! Желаем удачи!</p>
        </div>
      </div>
    </div>
  </div>
</section>
