import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { throwError, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class JudgeService {
  apiUrl = environment.apiUrl;
  public errors$: Subject<string> = new Subject<string>();
  public success$: Subject<string> = new Subject<string>();
  public isJudge$: Subject<boolean> = new Subject<boolean>();
  messageShow = false;
  constructor(private http: HttpClient) {}

  messageTimeout() {
    this.messageShow = true;
    setTimeout(() => {
      this.messageShow = false;
    }, 4000);
  }

  getVoteList() {
    return this.http.get(`${this.apiUrl}/v1/judge/vote/list`).pipe(
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  createVoteList(list) {
    return this.http.post(`${this.apiUrl}/v1/judge/vote/list`, list).pipe(
      catchError((error) => {
        return throwError(error);
      }),
    );
  }

  updateVoteList(teamId: number, team) {
    return this.http.patch(`${this.apiUrl}/v1/judge/vote/${teamId}`, team, { observe: 'response' }).pipe(
      tap((r) => {
        if (r.status === 200) {
          this.success$.next(r.statusText);
          this.messageTimeout();
        }
      }),
      catchError((error) => {
        this.errors$.next(error);
        this.messageTimeout();
        return throwError(error);
      }),
    );
  }

  getTeamVote(teamId: number) {
    return this.http.get(`${this.apiUrl}/v1/judge/vote/${teamId}`).pipe(
      catchError((error) => {
        return throwError(error);
      }),
    );
  }
}
