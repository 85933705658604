import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { LoggedInGuard } from './services/logged-in.guard';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { SetPasswordComponent } from './pages/set-password/set-password.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [LoggedInGuard] },
  { path: 'reset-password', component: ResetPasswordComponent, canActivate: [LoggedInGuard] },
  { path: 'reset-password/confirm', component: SetPasswordComponent },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'registration',
    loadChildren: () => import('./pages/registration/registration.module').then((m) => m.RegistrationModule),
  },
  { path: 'coffeeshop', loadChildren: () => import('./pages/coffeeshop/coffeeshop.module').then((m) => m.CoffeeshopModule) },
  { path: 'judge', loadChildren: () => import('./pages/judge/judge.module').then((m) => m.JudgeModule) },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
